// @flow

import React from 'react';
import type { Node } from 'react';
import { Markdown } from '@riseart/common';
import { nl2br } from '@riseart/fe-utils';

type Props = {
  type: string,
  text: string,
  component?: string,
  plainTextWrapper?: string,
  markdownProps?: Object,
};

/**
 * ContentAttribute
 *
 * Renders content attribute based on their type
 *
 * @param {Props} props
 * @returns {Node}
 */
export function ContentAttribute({
  type,
  text,
  component: Component = 'div',
  plainTextWrapper: PlainTextWrapper = 'span',
  markdownProps = null,
}: Props): Node {
  const TYPE = {
    HTML: 'html',
    MARKDOWN: 'markdown',
    PLAIN: 'plain',
  };

  if (!text) {
    return null;
  }

  switch (type) {
    case TYPE.HTML:
      return <Component dangerouslySetInnerHTML={{ __html: text }} />;
    case TYPE.MARKDOWN:
      return <Markdown text={text} {...markdownProps} />;
    case TYPE.PLAIN:
    default:
      return <PlainTextWrapper dangerouslySetInnerHTML={{ __html: nl2br(text) }} />;
  }
}
